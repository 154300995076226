@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue;
  background-color: '#f2f2f2';
}
.displayBlock{
  display: block !important;
}
.displayNone{
  display: none !important;
}
.displayFlex {
  display: flex;
}
.displayInlineFlex {
  display: inline-flex;
}
.impulseMobileDetail {
  display: block;
}
.displayInline {
  display: inline;
}
.pdp_details-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination {
  background: none !important;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  box-shadow: none !important;
}

.pagination .item {
  background: none !important;
  color: #176db7 !important;
  text-decoration: underline !important;
  font-weight: bolder !important;
}

.pagination .active.item {
  color: #000000 !important;
  text-decoration: none !important;
}
.pagination .item:before {
  display: none;
}
.pagination .item[type='nextItem']::before {
  position: unset !important;
  margin-bottom: -0.3rem;
  display: block;
  background: none !important;
  color: #176db7 !important;
  text-decoration-color: #176db7 !important;
}

.disabled{
  cursor: not-allowed;
  color: #919191;
  text-decoration: none;
}

.styled-cb{
  -webkit-appearance: none;
}
.styled-cb + label{  
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
}
.styled-cb:checked + label:after{
  content: '✔';
}
.styled-cb:not(:checked) + label:after{
  content:'';
}