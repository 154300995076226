#snackbar,#snackbar-ms {
    visibility: hidden; 
    min-width: 18rem; 
    transform: translateX(-50%); 
    background-color: #030303; 
    color: #fff; 
    text-align: center; 
    border-radius: 1rem; 
    padding: 1rem; 
    position: fixed;
    z-index: 1001;
    left: 50%; 
    bottom: 5rem;
    font-family: HelveticaNeue;
  }

  #snackbar.show,#snackbar-ms.show {
    visibility: visible ;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
    animation: fadein 0.5s, fadeout 0.5s 4.5s;
  }
  #snackbar.show.left{
    text-align: left;
  }
.commonLoader {
  position: fixed !important;
}
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 5rem; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 5rem; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 5rem; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 5rem; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }