.menu_bar {
  margin: 0em 0em !important;
}

.ui.button.circular {
  color: #ffffff;
  background: transparent;
  margin: -0.5em 0 !important;
  font-size: 1.37rem !important;
}
.ui.input.search input,
.ui.input.search input:focus,
.ui.input.search input:active {
  border-radius: 50px;
  min-width: 220px;
}

.views-menu {
  color: #ffffff;
}
.desktop_menu {
  min-height: 88vh;
  background-color: #f2f2f2;
}

.sidebar-pusher {
  min-height: 100vh;
}

.pusher-menu {
  margin-bottom: 0;
}
.cart-counter {
  cursor: pointer;
}
.cart-counter1 {
  font-size: 0.6rem !important;
}
.hiddenInput {
  position: absolute;
  width: 0;
  z-index: -100;
}
.ui.pusher-menu.menu {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 86px;
}

@media only screen and (min-width: 767px){
  .ui.pusher-menu.menu {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 38px;
  }
}
@media only screen and (max-width: 1241px) {
  .pushable:not(body) {
    transform: none;
    position: relative;
    z-index: 10;
  }
  .header-top {
    z-index: 20;
  }
  .mobile-menu-bar.pushable{
    overflow-x: unset;
    overflow-y: unset;
    overflow:unset;
  }
  .mobile-menu-bar.pushable .pusher{
    overflow: unset;
    overflow-y: unset;
    overflow-x: unset;
  }
}
@media only screen and (max-width: 767px) {
  .desktop_menu{
    min-height: unset;
  }
  .header-top{
    height: 86px;
  }
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop_menu {
      min-height: 120vh;
      background-color: #f2f2f2;
    }
  } 